@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.hamburger-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 99;
}

#checkbox2 {
    display: none;
}

.toggle2 {
    position: relative;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition-duration: 0.5s;
}

.bars {
    width: 100%;
    height: 6px;
    background-color: rgb(92, 176, 255);
    border-radius: 6px;
}

#bar5 {
    transition-duration: 0.8s;
}

#bar4,
#bar6 {
    width: 80%;
}
label.toggle.toggle2 {
    z-index: 99;
}

#checkbox2:checked + .toggle2 .bars {
    position: absolute;
    transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 #bar5 {
    transform: scaleX(0);
    transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 #bar4 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 #bar6 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 {
    transition-duration: 0.5s;
    transform: rotate(180deg);
}

.offcanvas-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    width: 70%;
    max-height: 70%;
    overflow: auto;
}
.offcanvas-menu li {
    color: antiquewhite;
    font-weight: 400;
    margin: 10px;
}
.offcanvas-menu li {
    font-size: 1.25rem;
    position: relative;
    list-style: none;
    padding: 8px 16px;
}
/*Hover Effect*/
.offcanvas-menu li::before,
.offcanvas-menu li::after {
    content: "";
    position: absolute;
    width: 0;
    height: 5px;
    background-color: #ffffff;
    transition: all 0.3s; /* Animasyon süresi */
}

.offcanvas-menu li::before {
    left: 0;
    bottom: 0;
}

.offcanvas-menu li::after {
    right: 0;
    bottom: 0;
}

.offcanvas-menu li:hover::before {
    width: 50%; /* Hover durumunda ne kadar genişleyeceği */
}

.offcanvas-menu li:hover::after {
    width: 50%; /* Hover durumunda ne kadar genişleyeceği */
}
/*Hover Effect End*/
.offcanvas-menu.open {
    opacity: 1;
    visibility: visible;
}

/*Shine Effect*/
.menu-head {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 48px;
    color: antiquewhite;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
}
